import { Download } from '@mui/icons-material';
import { Container, Grid, Typography, Button, Snackbar } from '@mui/joy';
import { collection, getDocs, getFirestore } from 'firebase/firestore'; // Adjust import based on your setup
import { useState } from 'react';
import { format } from 'date-fns';

const Reports = () => {
  const [generatingCSV, setGeneratingCSV] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const exportToCSV = async () => {
    setGeneratingCSV(true);
    setSnackbarOpen(true);
    console.time('export');
    try {
      const participantsSnapshot = await getDocs(
        collection(getFirestore(), 'participants')
      );
      console.log('participants', participantsSnapshot.docs.length);
      const allData = [];
      const allFields = new Set(['redcapId']);

      for (const participantDoc of participantsSnapshot.docs) {
        const participantData = participantDoc.data();
        console.log(participantData);
        const redcapId = participantData.redcapId;

        // Function to flatten nested objects
        const flattenData = data => {
          const flattened = {};
          for (const key in data) {
            if (data.hasOwnProperty(key)) {
              if (data[key] && data[key].toDate) {
                flattened[key] = format(data[key].toDate(), 'yyyy-MM-dd');
              } else if (typeof data[key] === 'object' && data[key] !== null) {
                flattened[key] = JSON.stringify(data[key]);
              } else {
                flattened[key] = data[key];
              }
            }
          }
          return flattened;
        };

        const flattenedParticipantData = flattenData(participantData);

        // Add participant fields to allFields
        Object.keys(flattenedParticipantData).forEach(key =>
          allFields.add(key)
        );

        const responsesSnapshot = await getDocs(
          collection(participantDoc.ref, 'responses')
        );

        if (responsesSnapshot.empty) {
          allData.push({ redcapId, ...flattenedParticipantData });
        } else {
          responsesSnapshot.docs.forEach(responseDoc => {
            console.log(responseDoc.id);
            const responseData = responseDoc.data();
            const flattenedResponseData = flattenData(responseData);
            Object.keys(flattenedResponseData).forEach(key =>
              allFields.add(key)
            );
            allData.push({
              redcapId,
              ...flattenedParticipantData,
              ...flattenedResponseData
            });
          });
        }
      }

      // Add empty strings for missing fields after collecting all data
      allData.forEach(row => {
        allFields.forEach(field => {
          if (!(field in row)) row[field] = '';
        });
      });

      console.log(allData);

      const excludedFields = [
        'netResponses',
        'firstName',
        'lastName',
        'dob',
        'email',
        'phoneNumber',
        'userId',
        'fcmTokens'
      ];

      // Filter out excluded fields from allFields
      const includedFields = Array.from(allFields).filter(
        field => !excludedFields.includes(field)
      );

      // Use includedFields instead of allFields when creating CSV
      const csvRows = [];
      csvRows.push(includedFields.join(','));

      allData.forEach(row => {
        const values = includedFields.map(header => {
          const value = row[header] || '';
          return `"${value.toString().replace(/"/g, '""')}"`;
        });
        csvRows.push(values.join(','));
      });

      console.log(csvRows);

      const csvContent = csvRows.join('\n');
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'participants_responses.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setGeneratingCSV(false);
      console.timeEnd('export');
    } catch (error) {
      console.error('Error exporting data:', error);
      setGeneratingCSV(false);
      // Handle error (e.g., show an error message to the user)
    }
  };

  return (
    <Container>
      <Grid container alignItems="center">
        <Grid xs={12}>
          <Typography
            level="body-lg"
            endDecorator={
              <Button
                size="sm"
                variant="soft"
                loading={generatingCSV}
                startDecorator={<Download />}
                onClick={exportToCSV}
              >
                Export to CSV
              </Button>
            }
          >
            Download all participant responses
          </Typography>
        </Grid>
      </Grid>
      <Snackbar
        variant="soft"
        color="success"
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={3333}
      >
        This may take a couple of minutes...
      </Snackbar>
    </Container>
  );
};

export default Reports;
