import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  LinearProgress,
  Typography,
  Card,
  CardContent,
  Chip,
  Divider,
  CardOverflow,
  Grid,
  Snackbar,
  Textarea,
  Select,
  Checkbox,
  Stack,
  Option,
  List,
  ListItem,
  ListItemContent,
  AccordionGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/joy';
import {
  onSnapshot,
  getFirestore,
  doc,
  updateDoc,
  collection,
  query,
  orderBy
} from 'firebase/firestore';
import { format, parseISO } from 'date-fns';
import { Cake, Email, Phone } from '@mui/icons-material';

const ParticipantView = () => {
  const [participant, setParticipant] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [notes, setNotes] = useState('');
  const [responses, setResponses] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { participantId } = useParams();
  console.log('loading participant view', participantId);

  useEffect(() => {
    if (!participantId) {
      setError('Participant ID is missing');
      setLoading(false);
      return;
    }

    let unsubscribeParticipant;
    let unsubscribeResponses;

    const setupListeners = async () => {
      // Listener for participant document
      unsubscribeParticipant = onSnapshot(
        doc(getFirestore(), 'participants', participantId),
        async docSnapshot => {
          if (docSnapshot.exists()) {
            const participantData = docSnapshot.data();
            setParticipant(participantData);
            setNotes(participantData.notes || '');
          } else {
            setError('Participant not found');
          }
          setLoading(false);
        },
        error => {
          console.error('Error fetching participant:', error);
          setError('Error fetching participant data');
          setLoading(false);
        }
      );

      // Listener for responses subcollection
      const responsesCollectionRef = collection(
        getFirestore(),
        'participants',
        participantId,
        'responses'
      );
      const q = query(responsesCollectionRef, orderBy('timestamp', 'asc'));

      unsubscribeResponses = onSnapshot(
        q,
        querySnapshot => {
          const responsesData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setResponses(responsesData);
        },
        error => {
          console.error('Error fetching responses:', error);
        }
      );
    };

    setupListeners();

    // Cleanup function
    return () => {
      if (unsubscribeParticipant) unsubscribeParticipant();
      if (unsubscribeResponses) unsubscribeResponses();
    };
  }, [participantId]);

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <Typography color="danger">{error}</Typography>;
  }

  if (!participant) {
    return <Typography>No participant data available</Typography>;
  }

  console.log(participant);

  const handleNotesChange = async event => {
    const newNotes = event.target.value;
    setNotes(newNotes);

    try {
      await updateDoc(doc(getFirestore(), 'participants', participantId), {
        notes: newNotes
      });
    } catch (error) {
      console.error('Error updating notes:', error);
    }
  };

  const statuses = [
    'account created',
    'active',
    'withdrawn',
    'lost to follow up',
    'completed'
  ];

  const statHeading = str => (
    <Typography level="title-md" gutterBottom sx={{ mt: 3 }}>
      {str}
    </Typography>
  );

  const stat = ({ key, value, units = '', transform = x => x }) =>
    value !== undefined ? (
      <Typography level="body-sm" gutterBottom>
        <Typography fontWeight="bold">{key}</Typography>:{' '}
        {transform(value).toString()}
        {units.length > 2 ? ` ${units}` : units}
      </Typography>
    ) : (
      ''
    );

  return (
    <>
      <Grid container spacing={3} justifyContent="space-evenly" mb={9}>
        <Grid xs={12} md={6} lg={4}>
          <Card sx={{ boxShadow: 'lg' }}>
            <CardContent>
              <Typography
                level="title-lg"
                endDecorator={
                  <Stack spacing={1} direction="row">
                    <Chip variant="outlined" color="primary" size="sm">
                      {participant.studyName}
                    </Chip>
                    <Chip variant="outlined" color="neutral" size="sm">
                      Study ID: {participant.redcapId}
                    </Chip>
                  </Stack>
                }
                gutterBottom
              >
                {participant.firstName} {participant.lastName}
              </Typography>
              <Typography
                level="title-sm"
                startDecorator={<Email />}
                gutterBottom
              >
                {participant.email}
              </Typography>
              <Typography
                level="title-sm"
                startDecorator={<Phone />}
                gutterBottom
              >
                {participant.phoneNumber}
              </Typography>
              <Typography
                level="title-sm"
                startDecorator={<Cake />}
                gutterBottom
              >
                {format(parseISO(participant.dob), 'do MMMM yyyy')}
              </Typography>
              <Divider sx={{ my: 1 }} />
              {stat({
                key: 'Last survey response',
                value: participant.netResponses.timestamp,
                transform: x => format(x.toDate(), 'do MMMM yyyy')
              })}
              {stat({
                key: 'Total survey responses',
                value: participant.netResponses.totalSurveys
              })}
              <Divider sx={{ my: 1 }} />
              {participant.netResponses.timestamp && statHeading('Main stats')}
              {stat({
                key: 'Height',
                value: participant.netResponses.height,
                units: 'cm'
              })}
              {stat({
                key: 'Weight',
                value: participant.netResponses.current_weight,
                units: 'kg'
              })}
              {stat({
                key: 'Gender at birth',
                value: participant.netResponses.gender_sex_assigned_at_birth
              })}
              {stat({
                key: 'Gender identity',
                value: participant.netResponses.gender_identity
              })}
              {stat({
                key: 'Relationship type',
                value: participant.netResponses.relationship_type
              })}
              {stat({
                key: "Partner's name",
                value: participant.netResponses.partners_name
              })}
              {stat({
                key: "Partner's date of birth",
                value: participant.netResponses.partners_dob,
                transform: x => format(parseISO(x), 'do MMMM yyyy')
              })}
              {stat({
                key: "Partner's gender at birth",
                value:
                  participant.netResponses.partner_gender_sex_assigned_at_birth
              })}
              {stat({
                key: "Partner's gender identity",
                value: participant.netResponses.partner_gender_identity
              })}

              {participant.netResponses.timestamp &&
                statHeading('Pregnancy related')}
              {stat({
                key: 'First day of last period',
                value: participant.netResponses.first_day_last_period,
                transform: x => format(parseISO(x), 'do MMMM yyyy')
              })}
              {stat({
                key: 'Shortest cycle length',
                value: participant.netResponses.shortest_cycle_length,
                units: 'days'
              })}
              {stat({
                key: 'Usual cycle length',
                value: participant.netResponses.usual_cycle_length,
                units: 'days'
              })}
              {stat({
                key: 'Longest cycle length',
                value: participant.netResponses.longest_cycle_length,
                units: 'days'
              })}
              {stat({
                key: 'Pregnant',
                value: participant.netResponses.are_you_pregnant
              })}
              {stat({
                key: 'Pregnancy due date',
                value: participant.netResponses.pregnancy_due_date,
                transform: x => format(parseISO(x), 'do MMMM yyyy')
              })}
              {stat({
                key: 'Outcome of the recent pregnancy',
                value: participant.netResponses.outcome_recent_pregnancy
              })}
              {stat({
                key: 'Pregnancy ended',
                value: participant.netResponses.weeks_pregnancy_ended,
                units: 'weeks'
              })}
              {stat({
                key: "Baby's birth weight",
                value: participant.netResponses.baby_birth_weight,
                units: 'g'
              })}
              {stat({
                key: 'Delivery method',
                value: participant.netResponses.how_baby_delivered
              })}
              {stat({
                key: 'Currently breastfeeding',
                value: participant.netResponses.currently_breastfeeding
              })}
              {stat({
                key: 'Trying to get pregnant',
                value: participant.netResponses.been_trying_pregnant
              })}
              {stat({
                key: 'Methods for timing ovulation with sex',
                value: participant.netResponses.timing_methods_sex?.join(', ')
              })}
              {stat({
                key: 'Months trying to get pregnant pre program',
                value: participant.netResponses.how_many_months_trying_pregnant
              })}
              {stat({
                key: 'Cumulative months trying to get pregnant',
                value:
                  participant.netResponses
                    .how_many_months_cumulative_trying_pregnant
              })}
              {stat({
                key: 'Months trying to get pregnant',
                value: participant.netResponses.how_many_months_trying_pregnant
              })}
              {stat({
                key: 'Been using contraception',
                value:
                  participant.netResponses.not_pregnant_been_using_contraception
              })}
              {stat({
                key: 'Unprotected sex since last period (while not pregnant)',
                value:
                  participant.netResponses
                    .number_times_sex_unprotected_since_last_period
              })}
              {stat({
                key: 'Unprotected sex since last period before falling pregnant',
                value:
                  participant.netResponses
                    .how_pregnant_number_times_unprotected_sex
              })}
              {stat({
                key: 'Method to get pregnant',
                value: participant.netResponses.how_trying_get_pregnant
              })}
              {stat({
                key: 'Ultrasound location during pregnancy',
                value: participant.netResponses.imaging_provider
              })}
              {participant.netResponses.timestamp &&
                statHeading('Medical stats')}
              {stat({
                key: 'Current medications',
                value: participant.netResponses.current_medications
              })}
              {stat({
                key: 'Conditions diagnosed during pregnancy',
                value:
                  participant.netResponses.conditions_diagnosed_pregnancy?.join(
                    ', '
                  )
              })}
              {stat({
                key: 'Last tubal analysis performed',
                value: participant.netResponses.lastTubalPatencyPerformed,
                transform: x => format(parseISO(x), 'do MMMM yyyy')
              })}
              {stat({
                key: 'Tubal patency test location',
                value: participant.netResponses.tubal_patency_test_location
              })}
              {stat({
                key: 'Tubal patency test result',
                value: participant.netResponses.tubal_patency_result
              })}
              {stat({
                key: 'Last semen analysis performed',
                value: participant.netResponses.lastSemenAnalysisPerformed,
                transform: x => format(parseISO(x), 'do MMMM yyyy')
              })}
              {stat({
                key: 'Semen analysis location',
                value: participant.netResponses.semen_test_location
              })}
              {stat({
                key: 'Semen analysis results',
                value: participant.netResponses.semen_analysis_results
              })}
              {stat({
                key: 'Endometriosis surgery date',
                value: participant.netResponses.endometriosis_surgery_date,
                transform: x => format(parseISO(x), 'do MMMM yyyy')
              })}
              {stat({
                key: 'Hospital for surgery',
                value: participant.netResponses.surgery_hospital
              })}
              {stat({
                key: 'Surgeon',
                value: participant.netResponses.surgery_surgeon
              })}
            </CardContent>
            <CardOverflow variant="soft" sx={{ bgcolor: 'background.level1' }}>
              <Divider inset="context" />
              <CardContent orientation="horizontal">
                <Typography
                  level="body-xs"
                  fontWeight="md"
                  textColor="text.secondary"
                >
                  Account created:{' '}
                  {format(participant.accountCreated.toDate(), 'do MMMM yyyy')}
                </Typography>
              </CardContent>
            </CardOverflow>
          </Card>
        </Grid>
        <Grid xs={12} md={6} lg={4}>
          <Stack gap={3}>
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <Typography level="title-md" gutterBottom>
                  Actions
                </Typography>
                <Divider />
                <List>
                  <ListItem
                    endAction={
                      <Select
                        value={participant.status}
                        onChange={async (event, newValue) => {
                          await updateDoc(
                            doc(getFirestore(), 'participants', participantId),
                            {
                              status: newValue
                            }
                          );
                          setSnackbarOpen(true);
                        }}
                      >
                        {statuses.map(status => (
                          <Option value={status} key={status}>
                            {status}
                          </Option>
                        ))}
                      </Select>
                    }
                  >
                    <ListItemContent>Status</ListItemContent>
                  </ListItem>
                  <ListItem
                    endAction={
                      <Checkbox
                        checked={participant.followUp}
                        onChange={event =>
                          updateDoc(
                            doc(getFirestore(), 'participants', participantId),
                            {
                              followUp: event.target.checked,
                              followUpReason: ''
                            }
                          )
                        }
                        value={participant.followUp}
                      />
                    }
                  >
                    <ListItemContent>
                      <Typography level="title-sm">
                        Follow up with {participant.firstName}?
                      </Typography>
                      {participant.followUpReason && (
                        <Typography level="body-xs" color="warning">
                          {participant.followUpReason}
                        </Typography>
                      )}
                    </ListItemContent>
                  </ListItem>
                </List>
              </CardContent>
            </Card>
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <Typography level="title-md" gutterBottom>
                  Admin Notes
                </Typography>
                <Textarea
                  minRows={5}
                  value={notes}
                  onChange={handleNotesChange}
                  onBlur={() => setSnackbarOpen(true)}
                  placeholder="Enter notes here..."
                />
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <Typography level="title-md" gutterBottom>
                  Participant's Personal Notes
                </Typography>
                <Typography
                  level="body-sm"
                  component="pre"
                  sx={{
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word'
                  }}
                >
                  {participant.personalNotes || 'No personal notes available.'}
                </Typography>
              </CardContent>
            </Card>
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <Typography level="title-md" gutterBottom>
                  Survey responses
                </Typography>
                <AccordionGroup>
                  {responses.length > 0 ? (
                    responses.map(response => (
                      <Accordion key={response.id}>
                        <AccordionSummary>
                          {format(response.timestamp.toDate(), 'do MMMM yyyy')}
                        </AccordionSummary>
                        <AccordionDetails>
                          <List>
                            {Object.entries(response).map(
                              ([key, value]) =>
                                key !== 'timestamp' && (
                                  <ListItem key={key}>
                                    <ListItemContent>
                                      <Typography level="body-sm">
                                        <strong>{key}:</strong>{' '}
                                        {JSON.stringify(value)}
                                      </Typography>
                                    </ListItemContent>
                                  </ListItem>
                                )
                            )}
                          </List>
                        </AccordionDetails>
                      </Accordion>
                    ))
                  ) : (
                    <Typography level="body-sm">No responses yet</Typography>
                  )}
                </AccordionGroup>
              </CardContent>
            </Card>
          </Stack>
        </Grid>
      </Grid>
      <Snackbar
        variant="soft"
        color="success"
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={3000}
      >
        Profile successfully updated
      </Snackbar>
    </>
  );
};

export default ParticipantView;
